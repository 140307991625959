import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import BeewiseTooltip from '@beewise/tooltip';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Cell } from 'recharts';
// import { fetchBeeActivitiesOverTime } from 'components/views/GrowerView/actions';
import { getBeeActivitiesOverTime } from 'components/views/GrowerView/selectors';
import '../ReportContainer.scss';
import './BeeActivity.scss';
import { MIN_DISPLAY_DAYS } from 'components/constants';
import { getRanchData } from 'data';

const getCellColor = ({ entry, cutoffDate }) =>
    new Date(entry.fullDate) < new Date(cutoffDate) ? '#E0E0E0' : '#4392F1';

const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload?.length) {
        return null;
    }

    const data = payload[0].payload;

    return (
        <div className="custom-tooltip">
            <p className="intro">Date: {data.date}</p>
            <p className="intro">Bee activity: {data.pollinationPotential}%</p>
        </div>
    );
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.shape()),
};

const CustomLegend = () => (
    <div className="custom-legend">
        <ul>
            <li className="text">
                <span className="legend-icon pre-bloom" /> Bee activity pre-bloom
            </li>
            <li className="text">
                <span className="legend-icon activity" /> Bee activity
                <BeewiseTooltip
                    content={
                        <p>
                            Weather conditions, including precipitation, temperature, and solar radiation, along with{' '}
                            <br />
                            hive strength and the ratio of hives per acre, are key factors influencing bee activity. The{' '}
                            <br />
                            benchmark for assessment is based on an 8-frame hive on a sunny day with full bloom.
                        </p>
                    }
                    position="right"
                >
                    <FontAwesomeIcon icon={faCircleExclamation} className="tooltip-icon" />
                </BeewiseTooltip>
            </li>
        </ul>
    </div>
);

const BeeActivity = ({ ranchId, startDate, endDate, isHardcodedData }) => {
    const dispatch = useDispatch();
    const beeActivitiesOverTimeDb = useSelector(getBeeActivitiesOverTime, arrayOfObjectsShallowEqual);

    const beeActivitiesOverTime = isHardcodedData ? getRanchData(ranchId) : beeActivitiesOverTimeDb;

    useEffect(() => {
        // TODO move to the top level
        if (startDate) {
            // dispatch(fetchBeeActivitiesOverTime({ ranchId, startDate, endDate }));
        }
    }, [dispatch, endDate, ranchId, startDate]);

    const getIsDataValid = beeActivitiesOverTime => {
        if (beeActivitiesOverTime?.length >= MIN_DISPLAY_DAYS) {
            const lastFiveDaysData = beeActivitiesOverTime.slice(-5);
            return lastFiveDaysData.some(item => item.pollinationPotential);
        }
        return false;
    };

    const isDataValid = getIsDataValid(beeActivitiesOverTime);

    if (!isDataValid) {
        return null;
    }

    const processedData = beeActivitiesOverTime.map(item => ({
        ...item,
        date: dayjs(item.date).format('MM/DD'),
        fullDate: new Date(item.date).toISOString(),
        pollinationPotential: item.pollinationPotential,
    }));

    return (
        <div className="report-container">
            <h3 className="header">Bee Activity Over Time</h3>
            <BarChart width={878} height={300} data={processedData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis domain={[0, 100]} tickFormatter={tick => `${Math.round(tick)}%`} />
                <Tooltip content={<CustomTooltip />} />
                <Legend content={CustomLegend} />
                <Bar dataKey="pollinationPotential">
                    {processedData.map((entry, index) => (
                        <Cell key={`${entry.fullDate}${index}`} fill={getCellColor({ entry, cutoffDate: startDate })} />
                    ))}
                </Bar>
            </BarChart>
        </div>
    );
};

BeeActivity.propTypes = {
    ranchId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    isHardcodedData: PropTypes.bool,
};

export default memo(BeeActivity);
