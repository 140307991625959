import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MapV2 from 'components/reusables/Map/components/MapV2';
import MapInfo from 'components/reusables/Map/components/MapInfo';
import { getBeekeeperByIds } from 'components/views/BrokerView/selectors';
import RanchMarkerV2 from 'components/reusables/Map/components/RanchMarkerV2';
import LocationMarkerv2 from 'components/reusables/Map/components/LocationMarkerv2';
import LocationMarkerWithFramePopulation from 'components/reusables/Map/components/LocationMarkerv2/LocationMarkerWithFramePopulation';
import PinMarker from 'components/reusables/Map/components/PinMarkerv2';
import { getBeekeeperColor } from 'utils/dataModelGetters';
import HeatMap from 'components/reusables/Map/components/HeatMap';
import useHeatMapData from 'components/reusables/Map/components/HeatMap/useHeatMapData';
import useMap from '../../../hooks/useMap';

const getMarkerComponent = (isBhomeView, getPopulatedFramesByLocationIdSelector) => {
    if (!isBhomeView) {
        return RanchMarkerV2;
    }

    if (getPopulatedFramesByLocationIdSelector) {
        return LocationMarkerWithFramePopulation;
    }

    return LocationMarkerv2;
};

const MarkerComponent = ({ isBhomeView, getPopulatedFramesByLocationIdSelector, ...props }) => {
    const MarkerComponent = getMarkerComponent(isBhomeView, getPopulatedFramesByLocationIdSelector);
    return (
        <MarkerComponent {...props} getPopulatedFramesByLocationIdSelector={getPopulatedFramesByLocationIdSelector} />
    );
};

MarkerComponent.propTypes = {
    isBhomeView: PropTypes.bool,
    getPopulatedFramesByLocationIdSelector: PropTypes.func,
};

const MapContainer = ({
    currentRanch,
    handleSelectRanch,
    filteredRanches,
    ranches,
    getPopulatedFramesByLocationIdSelector,
}) => {
    const beekeeperByIds = useSelector(getBeekeeperByIds);

    const { isHeatMapShown, setIsHeatMapShown, data: heatMapData } = useHeatMapData({ ranchId: currentRanch?.id });
    const {
        isBhomeView,
        handleMapChange,
        visibleMarkers,
        pinMarkers,
        handleLoadMaps,
        map,
        mapOptions,
        handleTogglePolygon,
    } = useMap({
        filteredRanches,
        ranches,
        selectedRanch: currentRanch,
        handleSelectRanch,
        isPolygonFilled: !isHeatMapShown,
    });

    const handleToggleHeatMap = value => {
        setIsHeatMapShown(value);
        handleTogglePolygon({ ranchId: currentRanch?.id, isSelected: !value });
    };

    return (
        <MapV2
            onGoogleApiLoaded={handleLoadMaps}
            extraOptions={
                <>
                    <MapInfo />
                    {heatMapData && (
                        <HeatMap
                            zoom={mapOptions.zoom}
                            map={map}
                            data={heatMapData}
                            isHeatMapShown={isHeatMapShown}
                            setIsHeatMapShown={handleToggleHeatMap}
                        />
                    )}
                </>
            }
            handleMapChange={handleMapChange}
        >
            {visibleMarkers.map(marker => (
                <MarkerComponent
                    key={marker.id}
                    lat={marker.lat}
                    lng={marker.lng}
                    handleMarkerClick={handleSelectRanch}
                    isBhomeView={isBhomeView}
                    beekeeperColor={getBeekeeperColor({ beekeeperByIds, marker })}
                    className={isHeatMapShown ? 'marker-transparent' : ''}
                    getPopulatedFramesByLocationIdSelector={getPopulatedFramesByLocationIdSelector}
                    {...marker}
                />
            ))}
            {pinMarkers.map(marker => (
                <PinMarker key={marker.id} {...marker} lat={marker.lat} lng={marker.lng} />
            ))}
        </MapV2>
    );
};

export default memo(MapContainer);

MapContainer.propTypes = {
    currentRanch: PropTypes.shape(),
    handleSelectRanch: PropTypes.func,
    isBrokerView: PropTypes.bool,
    filteredRanches: PropTypes.arrayOf(PropTypes.shape()),
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    getPopulatedFramesByLocationIdSelector: PropTypes.func,
};
