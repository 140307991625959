import { sortBy } from 'lodash-es';
import {
    FETCH_RANCHES,
    FETCH_USER,
    FETCH_FRAMES_PER_HIVE,
    FETCH_RANCH_APPROVAL,
    SET_RANCH_APPROVAL,
    FETCH_BEE_ACTIVITIES_OVER_TIME,
    FETCH_HIVE_DEVELOPMENT,
    FETCH_RANCH_BY_ID,
    SET_SELECTED_RANCH_ID,
    FETCH_POPULATED_FRAMES,
    FETCH_RANCH_SETTINGS,
    FETCH_RANCH_SNAPSHOT,
} from '../actionTypes';

import { formatSortedHiveDevelopmentData } from './utils';

const grower = (
    state = {
        ranches: [],
        hiveDevelopmentData: [],
        ranchBroker: null,
        framesPerHiveByBhomeId: {},
        selectedRanchApproval: null,
        beeActivitiesOverTime: [],
        selectedRanchId: null,
        selectedRanch: null,
        populatedFrames: [],
        ranchSettings: null,
    },
    action
) => {
    switch (action.type) {
        case FETCH_RANCHES.success:
            const sortedRanches = sortBy(action.data.ranches, [
                o => o?.locations.filter(location => location.bhomeIds?.length).length,
            ]);
            return {
                ...state,
                ranches: sortedRanches,
                selectedRanchId: state.selectedRanchId || sortedRanches[0]?.id,
            };
        case FETCH_USER.success:
            return {
                ...state,
                ranchBroker: action.data,
            };
        case FETCH_FRAMES_PER_HIVE.success:
            return {
                ...state,
                framesPerHiveByBhomeId: action.data,
            };
        case FETCH_RANCH_BY_ID.success:
            return {
                ...state,
                selectedRanchId: action.data?.id,
            };
        case FETCH_RANCH_APPROVAL.success:
        case SET_RANCH_APPROVAL.success:
            return {
                ...state,
                selectedRanchApproval: action.data,
            };
        case FETCH_BEE_ACTIVITIES_OVER_TIME.success:
            return {
                ...state,
                beeActivitiesOverTime: action.data,
            };

        case FETCH_HIVE_DEVELOPMENT.success:
            return {
                ...state,
                hiveDevelopmentData: formatSortedHiveDevelopmentData(action.data),
            };
        case SET_SELECTED_RANCH_ID:
            return {
                ...state,
                selectedRanchId: action.item,
            };
        case FETCH_POPULATED_FRAMES.success:
            return {
                ...state,
                populatedFrames: action.data,
            };
        case FETCH_RANCH_SETTINGS.success:
            return {
                ...state,
                ranchSettings: action.data,
            };
        case FETCH_RANCH_SNAPSHOT.success:
            // snapshots do not have lat/lng, so we need to add them for correct map handling. Coordinates will be added to ranch snapshot model later
            const ranchWithCoordinates = action.data?.ranch
                ? {
                      ...action.data?.ranch,
                      lat: action.data?.ranch.polygons[0][0].lat,
                      lng: action.data?.ranch.polygons[0][0].lng,
                  }
                : null;
            return {
                ...state,
                selectedRanch: ranchWithCoordinates,
                selectedRanchId: action.data?.ranch?.id,
                beeActivitiesOverTime: action.data?.beeActivities ?? [],
                framesPerHiveByBhomeId: action.data?.bhomeFramesPerHiveData ?? {},
            };
        default:
            return state;
    }
};

export default grower;
