import { createSelector } from 'reselect';
import { keyBy } from 'lodash';
import { prepareFactoredData, prepareFactoredDataPerHive } from './utils';

export const getRanchSettings = state => state.growerView.ranchSettings;

export const getRanches = state => state.growerView.ranches;
export const getHiveDevelopmentData = createSelector(
    state => state.growerView.hiveDevelopmentData,
    getRanchSettings,
    prepareFactoredData({ field: 'beeFramesPerHive', shouldAddFrames: true })
);
export const getSelectedRanchId = state => state.growerView.selectedRanchId;
export const getRanchBroker = state => state.growerView.ranchBroker;
export const getFramesPerHiveByBhomeId = createSelector(
    state => state.growerView.framesPerHiveByBhomeId,
    getRanchSettings,
    prepareFactoredDataPerHive
);
// export const getSelectedRanch = createSelector(
//     getRanches,
//     getSelectedRanchId,
//     (ranches, selectedRanchId) => selectedRanchId && ranches.find(ranch => ranch.id === selectedRanchId)
// );
export const getSelectedRanch = state => state.growerView.selectedRanch;
export const getSelectedRanchApproval = state => state.growerView.selectedRanchApproval;
export const getBeeActivitiesOverTime = createSelector(
    state => state.growerView.beeActivitiesOverTime,
    getRanchSettings,
    prepareFactoredData({ field: 'pollinationPotential' })
);

export const getPopulatedFrames = createSelector(
    state => state.growerView.populatedFrames,
    populatedFrames => keyBy(populatedFrames, 'locationId')
);

export const getPopulatedFramesByLocationId = createSelector(
    getPopulatedFrames,
    (state, locationId) => locationId,
    (populatedFrames, locationId) => populatedFrames[locationId]
);
